import { database, functions } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import HomeDialog from '@components/dialogs/EditHomeDialog';
import NoteDialog from '@components/dialogs/NoteDialog';
import TimelineItemDialog from '@components/dialogs/TimelineItemDialog';
import UserSearchDialog from '@components/dialogs/UserSearchDialog';
import Error from '@components/error/Error';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import MoodboardGrid from '@components/moodboards/MoodboardGrid';
import Notes from '@components/notes/Notes';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import PriceSheet from '@components/price-sheet/PriceSheet';
import RoomGrid from '@components/room-grid/RoomGrid';
import SocialPreviews from '@components/social-previews/SocialPreviews';
import Timeline from '@components/timeline/Timeline';
import UserWidgetAlt from '@components/user-widget/UserWidgetAlt';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import HomeStatus from '@enum/HomeStatus';
import HomeType from '@enum/HomeType';
import useChatMembersHandler from '@hooks/useChatMembersHandler';
import useCollection from '@hooks/useCollection';
import useDocumentBySlugOrID from '@hooks/useDocumentBySlugOrID';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import IconPlus from '@mui/icons-material/Add';
import IconArrowDownward from '@mui/icons-material/ArrowDownward';
import IconAttachMoney from '@mui/icons-material/AttachMoney';
import IconChat from '@mui/icons-material/Chat';
import IconChevronLeft from '@mui/icons-material/ChevronLeft';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import IconOpenInNew from '@mui/icons-material/OpenInNew';
import IconShare from '@mui/icons-material/Share';
import IconStar from '@mui/icons-material/Star';
import IconStarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MetadataDialog from '@pages/metadata/MetadataDialog';
import routes from '@routes';
import useCommonStyles from '@styles/common.style';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import HomeIcons from '@ui/components/home-icons/HomeIcons';
import Nl2br from '@ui/components/nl2br/Nl2br';
import Protected from '@ui/components/protected/Protected';
import useNavigiatorShareSupported from '@ui/hooks/useNavigatorShareSupported';
import MessagePlusIcon from '@ui/icons/imaterial/communication/Message Plus.svg';
import checkRoles from '@ui/utils/checkRoles';
import formatDate from '@ui/utils/formatDate';
import getDataFromCollection from '@ui/utils/getDataFromCollection';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import homeMetaData from '@ui/utils/metadata/homeMetaData';
import parseAddress from '@ui/utils/parseAddress';
import slugOrID from '@ui/utils/slugOrID';
import staticMap from '@ui/utils/staticMap';
import userFullName from '@ui/utils/userFullName';
import makeAppUrl from '@utils/makeAppUrl';
import newId from '@utils/newId';
import { homePath } from '@utils/paths';
import { addDoc, collection as firestoreCollection, deleteDoc, doc as firestoreDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';

import useStyles from './Home.style';
import HomeOrdersTab from './HomeOrdersTab';
import HomePlannedButton from './HomePlannedButton';
import MoreServicesTab from './MoreServicesTab';

type DownloadAllRequest = { collection: string, filename: string };
type DownloadAllResponse = string;

const downloadAll = httpsCallable<DownloadAllRequest, DownloadAllResponse>(functions, 'http-downloadAll', { timeout: 540000 });
const createHomeChannel = httpsCallable(functions, 'http-createHomeChannel');

type TabTypes = 0 | 'info' | 'photos' | 'rooms' | 'moodboards' | 'members';
const validTabs = ['info', 'more-services', 'photos', 'rooms', 'moodboards', 'members'];

interface HomeParams {
  id: string;
  tab: string;
}

const Home = () => {
  const router = useRouter<HomeParams>();
  const { classes, cx, theme } = useStyles();
  const { classes: common } = useCommonStyles();
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);
  const { setChannel } = useChatMembersHandler();
  const { client } = useChatContext();
  const navigatorShareSupported = useNavigiatorShareSupported();

  const [priceSheetOpen, setPriceSheetOpen] = useState(false);
  const [downloadAllLoading, setDownloadAllLoading] = useState(false);
  const [createHomeChannelLoading, setCreateHomeChannelLoading] = useState(false);

  const { id: slug } = router.params;
  let { tab } = router.params;

  const { id, loading, doc, exists: homeDocExists } = useDocumentBySlugOrID<guesthouse.Home>(slug, 'homes');

  // Ensures that a mistyped tab takes user to home-info
  tab = validTabs.includes(tab) ? tab : undefined;

  // Ensures that a restricted tab takes user to home-info
  const orderTabAllowed = checkRoles(['admin', 'design_manager', 'customer_support', 'realtor', 'designer'], userContext.roles);
  const membersTabAllowed = checkRoles(['admin', 'design_manager', 'customer_support', 'designer'], userContext.roles);

  if (tab === undefined && !orderTabAllowed) {
    tab = 'info';
  }

  const { collection: timelineItemCollection } = useCollection(firestoreCollection(database, `homes/${id}/timeline`));
  const timelineItems = getDataFromCollection(timelineItemCollection);

  const home = useMemo(() => doc?.data() as guesthouse.Home, [doc]);

  const channel = useMemo<Channel>(() => {
    if (home?.channel_id) {
      client.queryChannels({
        type: 'home',
        cid: home.channel_id
      }, {}, { limit: 1 })
        .then((channels) => {
          if (channels.length) {
            const [channel] = channels;

            return channel;
          }
        });
    }

    return null;
  }, [home?.channel_id]);

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: TabTypes) => {
    if (!tab) {
      router.push(homePath(home), { cancelScroll: true });
    } else {
      router.push(homePath(home, tab), { cancelScroll: true });
    }
  };

  const addUserToHome = (type: 'client' | 'realtor' | 'design_manager' | 'designer', filterRoles: guesthouse.RoleTypes[]) => () => {
    asyncRender<guesthouse.User>(UserSearchDialog, { title: `Add ${type} to this home`, filterRoles: filterRoles })
      .then(async user => {
        await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), {
          [type]: user,
          members: [...home.members, user?.docID]
        });


        return user;
      })
      .then((user) => {
        if (channel) {
          try {
            channel.addMembers([user.docID]);
          } catch (e) {
            Sentry.captureException(e);
          }
        }

        return user;
      })
      .then(user => {
        notificationContext.setContext({ open: true, message: `${user.firstname} ${user.lastname} is now the ${type} for this home.` });
      })
      .catch((e) => {
        if (e) {
          Sentry.captureException(e);
          notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
        }
      });
  };

  // TODO proper error and skeleton
  if (loading) {
    return null;
  }

  if (!homeDocExists) {
    return <Error statusCode={'404'} />;
  }

  const mapUrl = staticMap(home?.address, {
    zoom: 15,
  });

  const isRealtor = () => home.realtor?.docID === userContext.user.uid;
  const isClient = () => home.client?.docID === userContext.user.uid;
  const { short, parsed } = parseAddress(home.address);

  const handleHomeChannelLoading = async () => {
    setCreateHomeChannelLoading(true);
    await createHomeChannel(home);
    setCreateHomeChannelLoading(false);
  };

  let backgroundImage = '';

  if (home.primaryPhoto?.large_url) {
    backgroundImage = home.primaryPhoto.large_url;
  } else {
    backgroundImage = mapUrl;
  }

  return (
    <>
      <div
        className={classes.hero}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className={common.heroOverlay} />
        <div className={cx(common.contentSpacing, classes.heroContent)}>
          <div className={classes.heroBack}>
            <Button
              data-test="home-back-to-homes-button"
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<IconChevronLeft />}
              className={classes.backButton}
              component={LinkBehavior}
              href={routes.homes.path}
            >
              Back to homes
            </Button>
          </div>

          <Grid
            container
            className={classes.heroInfo}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <div>
                <address style={{ fontStyle: 'normal' }}>
                  <Typography
                    data-test="home-page-title"
                    component="h1"
                    variant="h2"
                  >
                    {short}
                    {home.unit && `, Unit ${home.unit}`}
                    {(parsed.city || parsed.state || parsed.zip) && (
                      <>
                        <br />
                        <Typography
                          variant="h3Alt"
                          component="span"
                        >
                          {[parsed.city, parsed.state, parsed.zip].filter(i => i).join(', ')}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </address>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <div className={classes.mobileHorizontalScrollWrapper}>
                <div className={classes.mobileHorizontalScrollContainer}>

                  <Protected allowedRoles={['admin', 'design_manager', 'website_manager', () => userContext.data.verified]}>

                    <Tooltip
                      title={
                        home.published
                          ? 'Unpublish from marketplace'
                          : 'Publish to marketplace'
                      }
                    >
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          aria-label={
                            home.published
                              ? 'Unpublish from marketplace'
                              : 'Publish to marketplace'
                          }
                          onClick={async () => {
                            const published = !home.published;
                            const message = published
                              ? 'Home published'
                              : 'Home unpublished';

                            await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), { published })
                              .then(() => notificationContext.setContext({ open: true, message }))
                              .catch((e) => {
                                Sentry.captureException(e);
                                notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                              });
                          }}
                        >
                          {home.published ? 'Published' : 'Publish'}
                        </Button>
                      </span>
                    </Tooltip>
                  </Protected>

                  {home?.channel_id ? (
                    <Protected
                      allowedRoles={[
                        'admin',
                        () => userContext?.user?.uid && userContext?.user?.uid === home?.client?.docID,
                        () => userContext?.user?.uid && userContext?.user?.uid === home?.realtor?.docID,
                        () => userContext?.user?.uid && userContext?.user?.uid === home?.designer?.docID
                      ]}
                    >
                      <Tooltip title="Go to home channel">
                        <span style={{ marginRight: theme.spacing(1) }}>
                          <IconButton
                            size="small"
                            aria-label="Go to home channel"
                            className={classes.heroButton}
                            component={LinkBehavior}
                            href={generatePath(routes.channel.path, { channelID: home.channel_id })}
                          >
                            <IconChat />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Protected>
                  )
                    :
                    (
                      <Protected
                        allowedRoles={[
                          'admin', 'design_manager', 'customer_support', 'website_manager',
                          () => userContext?.user?.uid && userContext?.user?.uid === home?.designer?.docID
                        ]}
                      >
                        <Tooltip title="Create home channel">
                          <span style={{ marginRight: theme.spacing(1) }}>
                            <SubmitButton
                              className={classes.createHomeChannelButton}
                              size="small"
                              aria-label="Create home channel"
                              type="button"
                              color="secondary"
                              variant="contained"
                              isSubmitting={createHomeChannelLoading}
                              onClick={async () => {
                                await handleHomeChannelLoading();
                              }}
                            >
                              <MessagePlusIcon />
                            </SubmitButton>
                          </span>
                        </Tooltip>
                      </Protected>
                    )
                  }

                  <Protected allowedRoles={['admin', 'design_manager']}>
                    <Tooltip title={home.featured ? 'Remove home featured status' : 'Set home as featured'}>
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <IconButton
                          size="small"
                          aria-label={home.featured ? 'Remove home featured status' : 'Set home as featured'}
                          className={classes.heroButton}
                          onClick={async (e) => {
                            e.stopPropagation();

                            if (home.featured) {
                              await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), { featured: false });
                              notificationContext.setContext({ open: true, message: 'Home is no longer featured' });
                            } else {
                              await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), { featured: true });
                              notificationContext.setContext({ open: true, message: 'Home is now featured' });
                            }
                          }}
                        >
                          {
                            home.featured
                              ? (
                                <IconStar />
                              ) : (
                                <IconStarBorder />
                              )
                          }
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Protected>


                  <Protected allowedRoles={['admin', 'designer', isRealtor, isClient, 'design_manager', 'website_manager', 'customer_support']}>
                    <Tooltip title="View price sheet">
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <IconButton
                          size="small"
                          aria-label="view price sheet"
                          className={classes.heroButton}
                          onClick={() => setPriceSheetOpen(true)}
                        >
                          <IconAttachMoney />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Protected>

                  <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
                    <Tooltip title="Edit">
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <IconButton
                          size="small"
                          className={classes.heroButton}
                          aria-label="edit"
                          onClick={() => {
                            asyncRender(HomeDialog, {
                              userContext,
                              initialValues: home,
                              onSubmit: async (values) =>
                                await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), id), values)
                                  .then(() => {
                                    if (slugOrID(home) !== slugOrID(values)) {
                                      router.replace(homePath(values));
                                    }
                                  })
                            });
                          }}
                        >
                          <IconEdit />
                        </IconButton>

                      </span>
                    </Tooltip>
                  </Protected>

                  <Protected allowedRoles={['admin', 'design_manager']}>
                    <Tooltip title="Permanently delete">
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <IconButton
                          size="small"
                          aria-label="permanently delete"
                          className={classes.heroButton}
                          onClick={() => {
                            asyncRender(ConfirmDialog, {
                              title: 'Delete home',
                              content: 'Are you sure want to delete this home? This action cannot be undone.',
                              confirmText: 'Yes, Delete',
                            })
                              .then(() => {
                                return router.push(homePath());
                              })
                              .then(async () => {
                                await deleteDoc(firestoreDoc(firestoreCollection(database, 'homes'), id));
                              })
                              .then(() => {
                                notificationContext.setContext({ open: true, message: 'Home deleted' });
                              })
                              .catch((e) => {
                                if (e) {
                                  Sentry.captureException(e);
                                  notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                                }
                              });
                          }}
                        >
                          <IconDelete />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Protected>

                  {home.published && (
                    <Tooltip title="View on Guest House marketplace">
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <IconButton
                          size="small"
                          aria-label="view on Guest House marketplace"
                          className={classes.heroButton}
                          onClick={() => window.open(makeStoreUrl(`/shop/homes/${slugOrID(home)}`), '_blank')}
                        >
                          <IconOpenInNew />
                        </IconButton>

                      </span>
                    </Tooltip>
                  )}

                  {navigatorShareSupported && (
                    <Tooltip title="Share this page">
                      <span style={{ marginRight: theme.spacing(1) }}>
                        <IconButton
                          size="small"
                          aria-label="share"
                          className={classes.heroButton}
                          onClick={() => {
                            return navigator.share({
                              title: document.title,
                              url: makeAppUrl(homePath(home, tab)),
                            })
                              .catch(Sentry.captureException);
                          }}
                        >
                          <IconShare />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className={classes.mobileHorizontalScrollContainer}>
                  <Protected allowedRoles={[() => userContext.data.docID === home.client?.docID, () => userContext.user.uid === home.realtor?.docID]} >
                    <Button
                      size="small"
                      variant="contained"
                      color="white"
                      href={`mailto:${parsed.state === 'CO' ? 'colorado@guesthouseshop.com' : 'california@guesthouseshop.com'}?subject=Request De-install for ${short}`}
                      style={{
                        marginTop: theme.spacing(2),
                        marginRight: theme.spacing(5),
                      }}
                    >
                      Request De-install
                    </Button>
                  </Protected>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Grid
        container
        style={{ flex: 1, height: '100%' }}
      >
        <Grid
          item
          xs={12}
          md={7}
          lg={8}
          className={classes.infoWrapper}
        >
          <Tabs
            allowScrollButtonsMobile
            value={tab ?? 0}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={handleTabChange}
          >
            {(orderTabAllowed) && (
              <Tab
                label="Orders"
                data-test="orders"
              />
            )}
            <Tab
              label="Book More Services"
              data-test="more-services"
              value="more-services"
            />
            <Tab
              label="Home Info"
              data-test="home-info"
              value="info"
            />
            <Tab
              label="Photos"
              data-test="photos"
              value="photos"
            />
            <Tab
              label="Rooms"
              data-test="rooms"
              value="rooms"
            />
            <Tab
              label="Mood Boards"
              data-test="moodboards"
              value="moodboards"
            />
            {membersTabAllowed && (
              <Tab
                label="Members"
                data-test="members"
                value="members"
              />
            )}
          </Tabs>
          <div className={common.contentSpacing}>
            {!tab && (
              <Protected allowedRoles={['admin', 'designer', 'design_manager', 'customer_support', 'realtor']}>
                <HomeOrdersTab
                  homeID={id}
                  home={home}
                />
              </Protected>
            )}

            {tab === 'more-services' && (
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'customer_support', 'realtor']}>
              <MoreServicesTab  
                homeID={id}
                home={home}
              />
            </Protected>
            )}
            {tab === 'info' && (
              <div>
                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  style={{ marginBottom: theme.spacing(1) }}
                >
                  <Typography
                    component="h1"
                    variant="h4Alt"
                    display="inline"
                    style={{ marginRight: theme.spacing(2) }}
                  >
                    {HomeType[home.type]}

                    {home.status && (
                      <Chip
                        label={HomeStatus[home.status]}
                        style={{ marginLeft: theme.spacing(2), position: 'relative', top: -4, fontWeight: 300 }}
                      />
                    )}

                    {(home.install_date || home.deinstall_date) && (
                      <Chip
                        label={`${formatDate(home.install_date)} - ${formatDate(home.deinstall_date)}`}
                        style={{ marginLeft: theme.spacing(2), position: 'relative', top: -4, fontWeight: 300 }}
                      />
                    )}
                  </Typography>
                </Box>

                <Typography
                  component="p"
                  variant="h6Alt"
                >
                  <HomeIcons
                    home={home}
                    style={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(3) }}
                  />
                </Typography>

                {home.description && (
                  <Typography component="p">
                    <Nl2br text={home.description} />
                  </Typography>
                )}

                <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager']}>
                  <div style={{ marginTop: theme.spacing(3) }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<IconPlus />}
                      style={{ marginBottom: theme.spacing(2), marginLeft: 'auto', display: 'flex' }}
                      onClick={() => {
                        asyncRender(NoteDialog)
                          .then((text: string) => {
                            if (text) {
                              // const docID = newId();

                              const note: guesthouse.Note = {
                                created: Timestamp.fromDate(new Date()),
                                text,
                                owner: userContext.data,
                                docID: newId()
                              };

                              return addDoc(firestoreCollection(database, `homes/${home.docID}/notes`), note);
                            }
                          })
                          .catch((e) => {
                            if (e) {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            }
                          });
                      }}
                    >
                      Add note
                    </Button>
                    <Notes
                      id={home.docID}
                      collection="homes"
                    />
                  </div>
                </Protected>

                <Protected allowedRoles={['realtor']}>
                  <div style={{ marginTop: theme.spacing(3) }}>
                    <Notes
                      id={home.docID}
                      collection="homes"
                      type="lockbox"
                    />
                  </div>
                </Protected>

                <Protected allowedRoles={['admin', 'design_manager', 'website_manager']}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: theme.spacing(8) }}
                  >
                    <span style={{ marginRight: theme.spacing(2) }}>
                      <Typography
                        component="h2"
                        variant="h5Alt"
                      >
                        Web Previews
                      </Typography>
                    </span>
                    <Tooltip title="Update social metadata tags for this home">
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          asyncRender(MetadataDialog, { userContext, initialValues: home.metadata })
                            .then(async (metadata: guesthouse.MetaData) => {
                              await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), { metadata });
                            })
                            .catch((e) => {
                              Sentry.captureException(e);
                              notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                            });
                        }
                        }
                      >
                        Update metadata
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box>
                    <SocialPreviews
                      metadata={homeMetaData(home)}
                      url={makeStoreUrl(`/shop/homes/${slugOrID(home)}`)}
                    />
                  </Box>
                </Protected>

              </div>
            )}

            {tab === 'photos' && (
              <>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ marginBottom: theme.spacing(2) }}
                >

                  <SubmitButton
                    aria-label="Download All"
                    color="secondary"
                    variant="contained"
                    endIcon={<IconArrowDownward />}
                    isSubmitting={downloadAllLoading}
                    onClick={() => {
                      const filename = `Guest House Photos - ${short}`;

                      setDownloadAllLoading(true);
                      notificationContext.setContext({
                        open: true,
                        severity: 'info',
                        message: 'Your photo download is being prepared...'
                      });

                      downloadAll({ collection: `homes/${id}/photos`, filename })
                        .then(({ data }) => {
                          notificationContext.setContext({
                            open: true,
                            severity: 'info',
                            message: 'Your photos are being downloaded'
                          });

                          const anchor = document.createElement('a');

                          anchor.href = data;
                          anchor.download = `${filename}.zip`;
                          anchor.click();
                        })
                        .catch(e => {
                          if (e) {
                            Sentry.captureException(e);
                            notificationContext.setContext({
                              open: true,
                              severity: 'error',
                              message: 'Failed to download all images.'
                            });
                          }
                        })
                        .then(() => setDownloadAllLoading(false));
                    }}
                  >
                    Download All
                  </SubmitButton>
                </Box>
                <PhotoGrid
                  allowPrimaryPhoto
                  collection={`homes/${id}/photos`}
                  home={home}
                  showHomeInfo={false}
                  enableTagging={checkRoles(['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'media_manager'], userContext.roles)}
                />
              </>
            )}

            {tab === 'rooms' && (
              <RoomGrid home={home} />
            )}

            {tab === 'moodboards' && (
              <MoodboardGrid
                home={home}
                queryPath={`homes/${home?.docID}/moodboards`}
              />
            )}

            {tab === 'members' && (
              <Protected allowedRoles={['admin', 'design_manager', 'customer_support', 'designer']}>
                <Typography
                  component="div"
                  variant="h5Alt"
                >
                  Members
                </Typography>

                {
                  home.members.map(userID => (
                    <UserWidgetAlt
                      key={userID}
                      userID={userID}
                      style={{
                        margin: theme.spacing(2, 0)
                      }}
                      renderAction={(user) => {
                        return (
                          <Button
                            style={{ minWidth: 100 }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => {
                              if (userContext.flags.messaging) {
                                return setChannel([user.docID, userContext.data.docID]);
                              } else {
                                return window.open(`mailto:${user.email}`);
                              }
                            }}
                          >
                            Contact
                          </Button>
                        );
                      }}
                      onDelete={checkRoles(['admin', 'design_manager'], userContext.roles) ? (user) => {
                        asyncRender(ConfirmDialog, {
                          title: `Are you sure you want to remove ${userFullName(user)} from this home?`,
                          confirmText: 'Yes, Delete',
                        })
                          .then(async () => {
                            const homeMembers = home.members;
                            const newMembers = homeMembers.filter(memberID => memberID !== userID);

                            return await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), { members: newMembers });
                          })
                          .then(() => notificationContext.setContext({ open: true, message: `${userFullName(user)} has been removed from this home.` }))
                          .catch(Sentry.captureException);
                      } : undefined}
                    />
                  ))
                }

                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    asyncRender(UserSearchDialog, {
                      userContext,
                      title: 'Add member',
                    })
                      .then(async (user) => {
                        return updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), { members: [...home.members, user?.docID] });
                      })
                      .catch(e => {
                        if (e) {
                          Sentry.captureException(e);
                        }
                      });
                  }}
                >
                  Add member
                </Button>

              </Protected>
            )}
          </div>

        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          className={classes.timelineContactsWrapper}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{
              marginBottom: theme.spacing(1.5),
            }}
          >
            <Typography
              component="div"
              variant="h5Alt"
            >
              Project Info
            </Typography>
            <Typography
              component="div"
            >
              {timelineItems?.length > 0 && `${timelineItems?.filter(tli => tli.completed_date).length}/${timelineItems?.length}`}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={timelineItems?.length ? 100 / timelineItems.length * timelineItems?.filter(tli => tli.completed_date).length : 0}
            style={{
              marginBottom: theme.spacing(3),
              height: 2,
            }}
          />

          {home.client ? (
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserWidgetAlt
                style={{ marginBottom: theme.spacing(4) }}
                userID={home.client.docID}
                userType="client"
                renderAction={() => {
                  return (
                    <Button
                      style={{ minWidth: 100 }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        if (userContext.flags.messaging) {
                          return setChannel([home.client.docID, userContext.data.docID]);
                        } else {
                          return window.open(`mailto:${home.client.email}`);
                        }
                      }}
                    >
                      Contact
                    </Button>
                  );
                }}
                onDelete={checkRoles(['admin', 'design_manager'], userContext.roles) ? () => {
                  asyncRender(ConfirmDialog, {
                    title: `Are you sure you want to remove ${userFullName(home.client)} from this home?`,
                    confirmText: 'Yes, Delete',
                  })
                    .then(async () => {
                      const homeMembers = home.members;
                      const newMembers = homeMembers.filter(memberID => memberID !== home.client.docID);

                      await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), {
                        client: null,
                        members: newMembers
                      });
                    })
                    .then(() => {
                      if (channel) {
                        channel.removeMembers([home.client.docID]);
                      }
                    })
                    .then(() => notificationContext.setContext({ open: true, message: `${userFullName(home.client)} has been removed as the client.` }))
                    .catch(Sentry.captureException);
                } : undefined}
              />
            </Protected>
          ) : (
            <Protected allowedRoles={['admin', 'design_manager']}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginBottom: theme.spacing(2), height: 60 }}
              >
                <Button
                  style={{ minWidth: 100 }}
                  startIcon={<IconPlus />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  aria-label="add client"
                  onClick={addUserToHome('client', ['realtor'])}
                >
                  Client
                </Button>
              </Box>
            </Protected>
          )}

          {home.realtor ? (
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support']}>
              <UserWidgetAlt
                style={{ marginBottom: theme.spacing(4) }}
                userID={home.realtor.docID}
                userType="realtor"
                renderAction={() => {
                  return (
                    <Button
                      style={{ minWidth: 100 }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        if (userContext.flags.messaging) {
                          return setChannel([home.realtor.docID, userContext.data.docID]);
                        } else {
                          return window.open(`mailto:${home.realtor.email}`);
                        }
                      }}
                    >
                      Contact
                    </Button>
                  );
                }}
                onDelete={checkRoles(['admin', 'design_manager'], userContext.roles) ? () => {
                  asyncRender(ConfirmDialog, {
                    title: `Are you sure you want to remove ${userFullName(home.realtor)} from this home?`,
                    confirmText: 'Yes, Delete',
                  })
                    .then(async () => {
                      const homeMembers = home.members;
                      const newMembers = homeMembers.filter(memberID => memberID !== home.realtor.docID);

                      await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), {
                        realtor: null,
                        members: newMembers
                      });
                    })
                    .then(() => {
                      if (channel) {
                        channel.removeMembers([home.realtor.docID]);
                      }
                    })
                    .then(() => notificationContext.setContext({ open: true, message: `${userFullName(home.realtor)} has been removed as the realtor.` }))
                    .catch(Sentry.captureException);
                } : undefined}
              />
            </Protected>
          ) : (
            <Protected allowedRoles={['admin', 'design_manager']}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginBottom: theme.spacing(2), height: 60 }}
              >
                <Button
                  style={{ minWidth: 100 }}
                  startIcon={<IconPlus />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  aria-label="add realtor"
                  onClick={addUserToHome('realtor', ['realtor'])}
                >
                  Realtor
                </Button>
              </Box>
            </Protected>
          )}

          {home.designer ? (
            <Protected allowedRoles={['admin', 'designer', 'design_manager', 'website_manager', 'customer_support', 'realtor']}>
              <UserWidgetAlt
                style={{ marginBottom: theme.spacing(4) }}
                userID={home.designer.docID}
                userType="designer"
                renderAction={() => {
                  return (
                    <Button
                      style={{ minWidth: 100 }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        if (userContext.flags.messaging) {
                          return setChannel([home.designer.docID, userContext.data.docID]);
                        } else {
                          return window.open(`mailto:${home.designer.email}`);
                        }
                      }}
                    >
                      Contact
                    </Button>
                  );
                }}
                onDelete={checkRoles(['admin', 'design_manager'], userContext.roles) ? () => {
                  asyncRender(ConfirmDialog, {
                    title: `Are you sure you want to remove ${userFullName(home.designer)} from this home?`,
                    confirmText: 'Yes, Delete',
                  })
                    .then(async () => {
                      const homeMembers = home.members;
                      const newMembers = homeMembers.filter(memberID => memberID !== home.designer.docID);

                      await updateDoc(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), {
                        designer: null,
                        members: newMembers
                      });
                    })
                    .then(() => {
                      if (channel) {
                        channel.removeMembers([home.designer.docID]);
                      }
                    })
                    .then(() => notificationContext.setContext({ open: true, message: `${userFullName(home.designer)} has been removed as the designer.` }))
                    .catch(Sentry.captureException);
                } : undefined}
              />
            </Protected>
          ) : (
            <Protected allowedRoles={['admin', 'design_manager']}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginBottom: theme.spacing(2), height: 60 }}
              >
                <Button
                  startIcon={<IconPlus />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  aria-label="add designer"
                  onClick={addUserToHome('designer', ['designer', 'design_manager'])}
                >
                  Designer
                </Button>
              </Box>
            </Protected>
          )}

          <HomePlannedButton
            home={home}
          />

          <Timeline
            home={home}
          />

          <Protected allowedRoles={['admin', 'design_manager', 'designer', 'customer_support']}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                asyncRender(TimelineItemDialog, {
                  userContext,
                  title: 'Add task',
                })
                  .then(async (values) => {
                    const docID = newId();

                    await setDoc(firestoreDoc(firestoreCollection(firestoreDoc(firestoreCollection(database, 'homes'), home.docID), 'timeline'), docID), {
                      docID,
                      home_id: home.docID,
                      ...values
                    }, { merge: true });
                  })
                  .catch(e => {
                    if (e) {
                      Sentry.captureException(e);
                    }
                  });
              }}
            >
              Add task
            </Button>
          </Protected>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        open={priceSheetOpen}
        maxWidth="xl"
        onClose={() => setPriceSheetOpen(false)}
      >
        <DialogContent>
          <PriceSheet
            homeId={id}
            onPrint={() => setPriceSheetOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(Home);
